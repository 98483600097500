/* App.css */

/* Reset Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%; /* Full viewport height */
}

body {
  font-family: "Poppins", sans-serif;
  background: linear-gradient(145deg, #ffe4e6, #ffc1cf);
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  color: #6b3e3e;
  text-align: center;
  overflow: hidden; /* Prevent scrollbars from showing due to animation */
}

/* App Container */
.app {
  width: 100%; /* Full width usage */
  text-align: center;
  padding: 20px; /* Padding for smaller screens */
  box-sizing: border-box;
  position: relative; /* Ensure hearts can appear behind */
}

/* Countdown Container with Floating Animation */
.countdown-container {
  background: #fff0f5;
  border: 3px solid #ffb6c1;
  border-radius: 20px;
  padding: 30px 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  width: 90%; /* Take up most of the screen width on smaller devices */
  max-width: 600px; /* Limit the width for larger screens */
  margin: auto; /* Center horizontally */
  text-align: center;
  position: relative; /* Ensure floating hearts are positioned relative to this container */
  animation: floatUpDown 2.75s ease-in-out infinite; /* Floating up and down effect */
  z-index: 10; /* Ensure it is above the floating hearts */
}

/* Floating up and down animation */
@keyframes floatUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-25px); /* Move up */
  }
  100% {
    transform: translateY(0); /* Return to original position */
  }
}

/* Countdown Title */
.countdown-title {
  font-size: 2rem;
  font-weight: 700;
  color: #ff69b4;
  margin-bottom: 20px;
  font-family: 'Pacifico', 'Dancing Script', 'Cookie', cursive;
  text-shadow: 2px 2px 4px rgba(255, 182, 193, 0.6);
}

/* Countdown Timer Layout */
.countdown-timer {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.countdown-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffe4e6;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  min-width: 70px;
  flex: 1 1 calc(25% - 20px);
}

.countdown-box span {
  font-size: 2.5rem;
  font-weight: 700;
  color: #d6336c;
}

.countdown-box p {
  font-size: 1rem;
  color: #6b3e3e;
}

/* Floating Hearts Styles */
@keyframes floatHeartUp {
  0% {
    opacity: 1;
    transform: translateY(0) scale(1); /* Start from the bottom */
  }
  100% {
    opacity: 0;
    transform: translateY(-300px) scale(1.5); /* Move up and fade out */
  }
}

/* Media Queries */
@media (max-width: 768px) {
  .countdown-title {
    font-size: 1.8rem;
  }

  .countdown-box span {
    font-size: 2rem;
  }

  .countdown-box p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .countdown-container {
    padding: 20px 10px; /* Reduce padding on very small screens */
  }

  .countdown-title {
    font-size: 1.5rem;
  }

  .countdown-box {
    padding: 10px;
  }

  .countdown-box span {
    font-size: 1.8rem;
  }

  .countdown-box p {
    font-size: 0.8rem;
  }
}

/* Floating Hearts Styles */
@keyframes floatHeartUp {
  0% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(-300px) scale(1.5);
  }
}

/* Add random movement to hearts */
@keyframes randomMove {
  0% {
    transform: translateY(0) translateX(0); /* Start at the bottom center */
  }
  25% {
    transform: translateY(-50px) translateX(-30px); /* Move left */
  }
  50% {
    transform: translateY(-150px) translateX(20px); /* Move right */
  }
  100% {
    transform: translateY(-300px) translateX(0); /* Final position */
  }
}

.heart {
  position: absolute;
  font-size: 24px; /* Heart size */
  color: red; /* Heart color */
  opacity: 0;
  animation: floatHeartUp 4s ease-in-out infinite, randomMove 4s ease-in-out infinite;
  z-index: 0; /* Ensure hearts stay behind other content */
}

.selection-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  margin-top: 20px;
}

.selection-button {
  width: 250px;
  padding: 15px;
  border: none;
  border-radius: 12px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: not-allowed;
  transition: all 0.3s ease;
}

.selection-button.unlocked {
  background-color: #ffb6c1;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: floatUpDown 2s infinite;
}

.selection-button.unlocked:hover {
  background-color: #ff69b4;
}

.selection-button.locked {
  background-color: #ffe4e6;
  color: #aaa;
}
